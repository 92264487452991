import React, { useEffect, useState, useRef } from 'react';
import { useElementSize } from 'use-element-size';

import { useMediaQuery } from '@material-ui/core';

export const WaveUpSVG = ({ fill, opacity = '1', terms, shadow }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const browser = typeof window !== 'undefined';

  const onResize = () => {
    setHeight(ref.current?.clientHeight);
  };

  useEffect(() => {
    browser && window.addEventListener('resize', onResize);

    setHeight(ref.current?.clientHeight);
  });

  const xl = useMediaQuery('(min-width: 2700px)');
  return (
    <svg
      style={{
        marginTop: xl && terms ? '-250px' : `-${height}px`,
        position: 'relative',
        filter: shadow && 'drop-shadow(0px -20px 11px rgb(0 45 92 / 0.15))',
      }}
      role='presentation'
      ref={ref}
      viewBox='0 0 2160 263'
      fill={fill}
      fillOpacity={opacity}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Wave'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2160 262.5H0V0C360 80 720 120 1080 120C1440 120 1800 80 2160 0V262.5Z'
        fill={fill}
      />
    </svg>
  );
};
